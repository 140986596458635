/* eslint-disable
    camelcase,
    consistent-return,
    func-names,
    no-plusplus,
    no-return-assign,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

'general.coffee';

// Avoid `console` errors in browsers that lack a console.

(function () {
  const noop = function () {};

  const methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeStamp', 'trace', 'warn'];
  let {
    length,
  } = methods;
  const console = window.console || {};

  // Only stub undefined methods.
  while (length--) {
    console[methods[length]] = console[methods[length]] || noop;
  }

  if (!window.console) {
    return window.console = console;
  }
}());

$(() => {
  $('.mobile-nav .search-trigger').on('click', (e) => {
    e.preventDefault();
    return $('.mobile-nav form[action="/search"]').toggle(function () {
      const $this = $(this);
      if ($this.is(':visible')) {
        return $this.find(':text').focus();
      }
    });
  });

  let sidr_open = false;
  $('.side-menu-trigger').sidr({
    speed: 200,
    onOpen() {
      sidr_open = true;
      $('html').css({ overflow: 'hidden', position: 'fixed' });
      return $('body').css({ overflow: 'hidden', position: 'static' });
    },
    onClose() {
      return sidr_open = false;
    },
  });

  $('body').append('<div class="sidr-overlay"></div>');

  $(document).on('click', (e) => {
    if (sidr_open) {
      if (!$(e.target).closest('#sidr').length) {
        e.preventDefault();
      }
      $.sidr('toggle', 'sidr');
    const elem = $('#sidr')
    const tabbable = elem.find('select, input, textarea, button, a').filter(':visible');
    const firstTabbable = tabbable.first();
    const lastTabbable = tabbable.last();
    firstTabbable.focus();
    
    lastTabbable.on('keydown', function(e) {
        if (e.which === 9 && !e.shiftKey) {
            e.preventDefault();
            firstTabbable.focus();
        }
    });

    firstTabbable.on('keydown', function(e) {
        if (e.which === 9 && e.shiftKey) {
            e.preventDefault();
            lastTabbable.focus();
        }
    });

    elem.on('keyup', function(e) {
        if (e.keyCode === 27) {
            elem.hide();
        }
    });
return
    }
  });

  $('.side-menu-trigger').on('click', (e) => {
    if (!sidr_open) {
      e.preventDefault();
      return $.sidr('toggle', 'sidr');
    }
  });

  return $(window).on('orientationchange', () => $.sidr('close', 'sidr'));
});
